<script>
import TemplateMixin from '../mixin'
import {list} from '../../../../../domain/templates/services'
import windowPagina from './window/windowPagina'
import windowPaginasList from './window/windowPaginaList'

export default {
  name: 'Paginas',
  mixins: [TemplateMixin],
  data () {
    return {
      loading: false,
      templates: null,
      categories: null,
      categoria: null,
      buscaRight: null
    }
  },
  computed: {},
  mounted () {},
  destroyed () {
  },
  methods: {
    windowPagina,
    windowPaginasList,
    criar () {
      this.windowPagina()
    },
    listar () {
      this.windowPaginasList()
    }
  },
  meta: {
    title: 'Templates',
    name: 'Templates'
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap pages-container">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <header class="pages-header">
          <h1 class="pages-header-title">Automação de Páginas</h1>
          <h2 class="pages-header-subtitle">
            Configure todas as páginas do seu site
            <a href="https://help.suporteleiloes.com/templates" target="_blank" title="Ajuda" tabindex="0">Ajuda</a>
          </h2>
        </header>

        <div class="pages-body">
          <div class="box-connect"></div>
          <div class="tbox box-right">
            <div class="tbox-content">
              <h1 class="content-title text-center disabled">Selecione uma ação</h1>

              <div class="text-center m-t">
                <e-btn @click="criar" label="Criar nova página" />
              </div>
              <div class="text-center m-t">
                <e-btn @click="listar" label="Listar páginas criadas" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
