export default function (id) {
  if (typeof id === 'object'){
    id = null
  }
  let window = id ? `template.${id}` : 'template.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Variável' + (id ? ` #${id}` : ''),
    width: '85%',
    height: '85%',
    minHeight: '500px',
    maxHeight: '500px',
    backdrop: true,
    move: false,
    clickOutside: false,
    windowClass: 'erp-window-modern',
    contentClass: '',
    props: {
      id: id
    }
  }, () => import('./Pagina'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        created: (wid, val) => {
          console.log('Created event')
          this.load && this.load()
        }
      })
    }) // return wid
}
