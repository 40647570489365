export default function () {
  let window = 'paginas.site.list'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Lista de páginas cadastradas',
    width: '50%',
    // height: '85%',
    maxHeight: '500px',
    backdrop: true,
    move: false,
    clickOutside: false,
    windowClass: 'erp-window-modern',
    contentClass: '',
    props: {}
  }, () => import('./PaginaList'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        created: (wid, val) => {
          console.log('Created event')
          // this.load && this.load()
        }
      })
    }) // return wid
}
